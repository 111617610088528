














































































































import { computed, defineComponent, inject, PropType, ref, watchEffect } from "@vue/composition-api";
import Moment from "@/services/moment";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "LessonCardSeller",
  props: {
    lesson: {
      type: Object as PropType<Learnlink.Lesson.FullViewApp>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const name = computed(() => {
      if (props.lesson.project?.studentName) {
        return props.lesson.project.studentName;
      }
      return `${props.lesson.customer?.profile?.firstName} ${props.lesson.customer?.profile?.lastName}`;
    });

    const readableDate = computed(() => {
      return Moment.unix(props.lesson.startTime).format("dddd Do MMMM");
    });

    const readableTime = computed(() => {
      return `${Moment.unix(props.lesson.startTime).format("HH:mm")} - ${Moment.unix(props.lesson.endTime).format("HH:mm")}`;
    });

    const paid = computed(() => {
      return (
        props.lesson.subtracted ||
        (props.lesson.paymentID && props.lesson.paymentID !== "N/A")
      );
    });
    const customerID = computed(() => {
      return props.lesson.customerUID.slice(0, 3).toUpperCase();
    });

    const cancel = inject<(lesson: Learnlink.Lesson.FullViewApp) => void>("showCancelLessonDialog");
    const edit = inject<(lesson: Learnlink.Lesson.FullViewApp) => void>("showEditLessonDialog");

    function showCancelLessonDialog(): void {
      if (cancel) cancel(props.lesson);
    }

    function showEditLessonDialog(): void {
      if (edit) edit(props.lesson);
    }

    return {
      _value,
      customerID,
      name,
      paid,
      readableDate,
      readableTime,
      showCancelLessonDialog,
      showEditLessonDialog,
    };
  },
});
