








































import * as LessonService from "@/api/lessonService";
import { uiModule } from "@/store/modules/ui";
import {
  defineComponent,
  inject,
  PropType,
  reactive,
  ref,
  watchEffect,
} from "@vue/composition-api";
import { useNotifier } from "@/providers/notifier";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CancelLessonDialog",
  props: {
    lesson: {
      type: Object as PropType<Learnlink.Lesson.FullViewApp>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      loading: false,
    });

    const notify = useNotifier();
    const getLessons = inject<() => Promise<void>>("getLessons");

    async function cancel() {
      try {
        vm.loading = true;
        await LessonService.cancelLesson(props.lesson.ID);
        uiModule.actions.fetchIncompleteReportsCount();

        notify({
          title: "Kansellert 🙌",
          message: "",
          type: "success",
        });

        if (getLessons) await getLessons();

        const event = new Event("refreshReports");
        document.dispatchEvent(event);

        _value.value = false;
      }
      catch (e) {
        notify({
          title: "Klarte ikke kansellere timen 🤯",
          message: "Kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    return {
      cancel,
      vm,
      _value,
    };
  },
});
