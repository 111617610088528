import Learnlink from "@learnlink/interfaces";

export enum LOCATIONS {
  ONLINE = "online",
  PUBLIC = "public",
  HOME = "home",
}

export enum LocationsReadable {
  online = "Nettundervisning",
  public = "Offentlig sted",
  home = "Hjemmeundervisning",
}

export function mapLocation(location: string): string {
  return LocationsReadable[location as Learnlink.Lesson.Location];
}
